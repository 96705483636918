<template>
  <div class="row">    
    <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1" style="margin-bottom: 10px">
    <div class="pull-right">
      <b-button @click="add" class="btn btn-success" v-if="showForAdmin()">{{ $t("common.new") }}</b-button>
    </div>
  </div>
    <div class="col-xl-12 col-lg-12 order-lg-3 order-xl-1">
      <table class="table table-bordered">
        <thead class="thead-light">
        <tr>
          <th class="text-left">
            Класс
          </th>     
          <th class="text-left">
            Предмет
          </th>   
          <th class="text-left">
            Преподаватель
          </th>
          <th class="text-left">
            Дата с
          </th>
          <th class="text-left">
            Дата по
          </th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.groupName }}</td>
          <td>{{ item.groupSubjectName }}</td>
          <td>{{ item.teacherName }}</td>
          <td>{{ UtilsService.formatDate(item.dateFrom) }}</td>
          <td>{{ UtilsService.formatDate(item.dateTo) }}</td>
          <td>
<!--            <b-button
                type="submit"
                @click="editItem(item)"
                variant="primary"
                v-if="showForAdmin()"
            >
              {{ $t("common.edit") }}
            </b-button>-->
                        <b-button
                type="submit"
                @click="removeItem(item)"
                variant="danger"
                v-if="showForAdmin()"
            >
              {{ $t("common.delete") }}
            </b-button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <b-modal :ref="TEACHER_SUBSTITUTE_TEACHER_MODAL">
      <template v-slot:modal-title>
        {{ $t("common.new") }}
      </template>
      <b-form>
        <b-form-group>
          <template v-slot:label>
            Преподаватель
          </template>
          <b-form-select
              v-model="form.teacherId"
              :options="teachers"
              :state="substituteTeacherModelState.teacherId"
          ></b-form-select>
          <div class="invalid-feedback">{{ substituteTeacherModelSaveError.teacherId }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            С 
          </template>
          <b-form-datepicker
              v-model="form.dateFrom"
              locale="ru"
              start-weekday="1"
              :state="substituteTeacherModelState.dateFrom"
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ substituteTeacherModelSaveError.dateFrom }}</div>
        </b-form-group>
        <b-form-group>
          <template v-slot:label>
            По
          </template>
          <b-form-datepicker
              v-model="form.dateTo"
              locale="ru"
              start-weekday="1"
              :state="substituteTeacherModelState.dateTo || substituteTeacherModelState.subjectIds"
          ></b-form-datepicker>
          <div class="invalid-feedback">{{ substituteTeacherModelSaveError.dateTo }} {{ substituteTeacherModelSaveError.subjectIds }}</div>
        </b-form-group>
        
        <table class="table table-bordered">
          <thead class="thead-light">
          <tr>
            <th></th>
            <th>
              Класс
            </th>
            <th>
              Предмет
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in subjects" :key="item.id">
            <td>
              <input type="checkbox" v-model="item.selected">
            </td>
            <td>{{ item.groupName }}</td>
            <td>{{ item.subjectName }} {{ item.subgroupName }}</td>
          </tr>
          </tbody>
        </table>
        
      </b-form>
      <template v-slot:modal-footer>
        <b-button type="submit" @click="onSubmit" variant="primary">{{
            $t("common.save")
          }}
        </b-button>
        <b-button type="reset" @click="onReset" variant="danger">{{
            $t("common.cancel")
          }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<style>
.dropped td {
  background-color: #F64E60;
}
</style>
<script>
import ApiService from "@/common/api.service";
import UtilsService from "@/common/utils.service";
import {mapGetters} from "vuex";

const TEACHER_SUBSTITUTE_TEACHER_MODAL = "TeacherSubstituteTeacherAdd-modal";
export default {
  name: "SubstituteTeacher",
  components: {    
  },
  props: {
    teacherId: Number,
    postponeLoad: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "currentUser"
    ])
  },
  data() {
    return {
      items: [],
      form: {
        dateFrom: null,
        dateTo: null,
        teacherId: null
      },
      substituteTeacherModelState:{},
      substituteTeacherModelSaveError:{},
      teachers:[],
      subjects:[]
    };
  },
  mounted() {
    if(this.postponeLoad === false){
      this.reloadData();
    }
  },
  setup() {
    return { UtilsService, TEACHER_SUBSTITUTE_TEACHER_MODAL }
  },
  methods: {
    
    reloadData() {
      let $this = this;
      
      ApiService.querySecured("dictionaries/teachersLoggedUserSchool", {}).then(({data}) => {
        $this.teachers = data.dictionary;
      }).catch(({response}) => {
        console.log(response);
      });

      ApiService.querySecured("groupSubjects/getForTeacher", {
        params: {teacherId: this.teacherId}
      })
          .then(({data}) => {
            $this.subjects = data;
          })
          .catch(({response}) => {
            console.log(response);
          });
      
      
      ApiService.querySecured("groupSubjects/substituteTeacherListForTeacher", {
        params: {teacherId: this.teacherId}
      }).then(({data}) => {
        $this.items = data;
      }).catch(({response}) => {
        console.log(response);
      });
      
    },
    onSubmit(evt) {
      evt.preventDefault();
      let $this = this;

      this.substituteTeacherModelState = {};
      this.substituteTeacherModelSaveError = {};

      let ids = [];
      
      for (let i in this.subjects){
        let item = this.subjects[i];
        
        if(item.selected === true){
          let subId = '';
          if (item.groupSubjectSubGroupId != null) {
            subId = "sub-" + item.groupSubjectSubGroupId;
          } else {
            subId = item.groupSubjectId;
          }
          
          ids.push(subId);
        }
      }

      this.form.subjectIds = ids;

      ApiService.postSecured("groupSubjects/saveMultipleSubstituteTeacher", this.form)
          .then(function () {
            $this.$refs[TEACHER_SUBSTITUTE_TEACHER_MODAL].hide();
            $this.reloadData();
            $this.cleanModel();
          })
          .catch(({response}) => {
            if (response.data != null && response.data.errors != null) {
              $this.substituteTeacherModelSaveError = response.data.errors;

              for (let i in response.data.errors) {

                $this.substituteTeacherModelState[i] = false;
              }
            }
          });
    },
    add() {
      this.form = {};
      this.substituteTeacherModelState = {};
      this.substituteTeacherModelSaveError = {};
      
      this.$refs[TEACHER_SUBSTITUTE_TEACHER_MODAL].show();
    },
    editItem(item){
      this.form = item;
      this.$refs[TEACHER_SUBSTITUTE_TEACHER_MODAL].show();
    },  
    removeItem(item){
      let $this = this;
      if(confirm("Удалить?")){
        ApiService.postSecured("groupSubjects/removeSubstituteTeacher", {id:item.id})
            .then(function () {

              $this.reloadData();
            })
            .catch(() => {

            }).finally(function () {

        });
      }
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form = {};

      this.$refs[TEACHER_SUBSTITUTE_TEACHER_MODAL].hide();
    },
    showForAdmin(){
      return this.currentUser.role == 'ROLE_SCHOOL_ADMIN';
    },
  }
};
</script>
